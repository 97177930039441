<template>
  <div>
    <form @submit.prevent="saveMarketingEmailTemplate">
      <div class="row">
        <div class="col">
          <input
            type="text"
            class="form-control"
            placeholder="Template name"
            v-model="form.name"
            required
          />
        </div>
        <div class="col-auto">
          <select class="form-control" v-model="form.status" required>
            <option value="draft">Draft</option>
            <option value="active">Active</option>
          </select>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary float-end mb-3" type="submit">
            <i class="far fa-save me-1" />Save
          </button>
        </div>
      </div>
    </form>

    <div
      class="card border-0 mb-3"
      :class="!showMoreShortcodes ? 'cursor-pointer' : ''"
      @click="
        showMoreShortcodes = showMoreShortcodes ? showMoreShortcodes : true
      "
    >
      <div class="card-body">
        <h6 class="card-header bg-primary text-white">Shortcodes</h6>

        <div
          class="position-absolute text-primary"
          :class="showMoreShortcodes ? 'cursor-pointer' : ''"
          style="right: 7px; top: 7px"
        >
          <i
            class="far fa-caret-circle-down"
            v-if="!showMoreShortcodes"
            @click.stop="showMoreShortcodes = true"
          />
          <i
            class="far fa-caret-circle-up"
            v-if="showMoreShortcodes"
            @click.stop="showMoreShortcodes = false"
          />
        </div>

        <div class="row mt-4" v-if="showMoreShortcodes">
          <div class="col">
            <p class="alert alert-dark mb-2">
              <i class="far fa-info-circle text-primary me-1" />Insert dynamic
              content<b>Shortcodes</b>See the
            </p>
            <div class="card border-0">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th scope="col">Shortcode</th>
                    <th scope="col">Description</th>
                    <th scope="col">Sample of shortcode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-0" v-pre>{{ contact-first-name }}</td>
                    <td class="border-0">Target Contact First Name</td>
                    <td class="border-0">John</td>
                  </tr>
                  <tr>
                    <td class="border-0" v-pre>{{ contact-last-name }}</td>
                    <td class="border-0">Target Contact Last Name</td>
                    <td class="border-0">Doe</td>
                  </tr>
                  <tr>
                    <td class="border-0" v-pre>{{ contact-email }}</td>
                    <td class="border-0">Target Contact Email Address</td>
                    <td class="border-0">john@doe.com</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EmailEditor
      style="height: 80vh !important"
      :appearance="appearance"
      :project-id="projectId"
      :locale="locale"
      :tools="tools"
      :options="options"
      ref="emailEditor"
      v-on:load="editorLoaded"
    />
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
export default {
  components: { EmailEditor },
  props: ["marketingEmailTemplate", "defaultTemplate"],
  data() {
    return {
      form: this.marketingEmailTemplate ?? { status: "draft" },
      showMoreShortcodes: false,
      minHeight: "3000px",
      locale: "en",
      projectId: 60819, // replace with your project id
      tools: {},
      options: {},
      appearance: {
        theme: "light",
        panels: {
          tools: {
            dock: "right",
          },
        },
      },
    };
  },
  methods: {
    saveMarketingEmailTemplate() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.form.design = data.design;
        this.form.html = data.html;

        if (this.form.id) {
          this.$axios
            .put(
              process.env.VUE_APP_API_URL +
                "/marketing-email-templates/" +
                this.form.id,
              this.form
            )
            .then(({ data }) => {
              console.log(data);
              this.$EventBus.$emit("alert", data);
            });
        } else {
          this.$axios
            .post(
              process.env.VUE_APP_API_URL + "/marketing-email-templates",
              this.form
            )
            .then(({ data }) => {
              console.log(data);
              this.$EventBus.$emit("alert", data);
              this.form = data.marketing_email_template;
            });
        }
      });
    },
    editorLoaded() {
      // Pass the template JSON here
      if (this.form.design) {
        this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.form.design));
      }
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log("exportHtml", data);
      });
    },
  },
  created() {
    if (this.defaultTemplate) {
      this.form.design = this.defaultTemplate.design;
    }
  },
};
</script>
