<template>
  <div>
    <DataList
      :api-resource="baseUrl + '/marketing-email-templates'"
      display-name="Marketing Email Template"
      @navigateTo="navigateTo"
      :fields="dataListFields"
      :hide-headers="true"
      search="true"
      search-label="Search email templates..."
      default-sort="created_at"
    />
  </div>
</template>

<script>
import DataList from "@/views/components/tables/DataList";
export default {
  components: { DataList },
  data() {
    return {
      dataListFields: [
        {
          field_name: "name",
          display_name: "Name",
          type: "text",
          sortable: true,
          highlight: true,
          image: {
            field_name: "image_url",
            height: 100,
            width: 80,
            rounded: false,
          },
        },
        {
          field_name: "status",
          display_name: "Status",
          type: "badge",
          badge_data: {
            active: "btn-success",
            draft: "btn-light text-dark",
          },
          capitalize: true,
          hide_sm: true,
          class_list: "text-end",
        },
      ],
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  methods: {
    navigateTo(marketingEmailTemplate) {
      this.$emit("selected", marketingEmailTemplate);
    },
  },
};
</script>
