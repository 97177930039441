<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <!--  -->
        <div v-if="!addNew && !viewTemplate">
          <div class="row">
            <div class="col">
              <h1 class="fw-bold text-uppercase text-secondary">
                Email Templates
              </h1>
            </div>
            <div class="col-auto ms-auto">
              <button
                class="btn btn-outline-secondary btn-sm"
                @click="$router.push('/marketing')"
              >
                <i class="far fa-arrow-left me-2"></i>Marketing
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <p class="text-muted">
                Create beautiful email templates to send to your marketing
                lists. Use our intuitive drag &amp; drop editor to build rich
                and engaging emails. We have a range of templates that you can
                use as a basis for your own emails.
              </p>
            </div>
            <div class="col-auto ms-auto">
              <button class="btn btn-primary" @click="addNew = true">
                <i class="far fa-plus-circle me-2" />Create New Template
              </button>
            </div>
          </div>
        </div>

        <div v-if="viewTemplate">
          <MarketingEmailTemplateEditor
            :marketing-email-template="viewTemplate"
          />
        </div>
        <div v-else>
          <MarketingEmailTemplatesList
            v-if="!addNew"
            @selected="onSelectedTemplate"
          />

          <div v-if="addNew">
            <div v-if="useTemplate === false">
              <MarketingEmailTemplateEditor />
            </div>
            <div v-else-if="useTemplate === true">
              <p class="alert alert-dark">
                <i class="far fa-info-circle me-1 text-primary" />Browse our
                selection
              </p>
              <busy v-if="loadingDefaults" />
              <div v-else>
                <div class="row">
                  <div
                    v-for="dt in defaultTemplates"
                    class="col col-sm-6 col-md-4 col-xl-3 mb-4"
                  >
                    <div
                      class="
                        card
                        border-0
                        bg-light
                        cursor-pointer
                        text-center
                        h-100
                      "
                      @click="useTemplate = dt"
                    >
                      <img
                        :src="dt.image_url"
                        class="card-img-top"
                        style="
                          max-height: 200px;
                          width: auto !important;
                          object-fit: cover;
                        "
                      />
                      <div class="card-body">
                        <h6 class="my-auto">{{ dt.name }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="useTemplate !== null">
              <MarketingEmailTemplateEditor :default-template="useTemplate" />
            </div>
          </div>

          <div v-if="addNew && useTemplate === null">
            <div class="row justify-content-center mt-4">
              <div class="col-md-4">
                <div
                  class="
                    card
                    bg-light
                    border-0
                    cursor-pointer
                    btn-outline-primary
                  "
                  @click="useTemplate = false"
                >
                  <div class="card-body text-center">
                    <i class="far fa-paint-brush-alt fa-5x text-primary mb-4" />
                    <h5 class="fw-bold">Start from scratch</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="
                    card
                    bg-light
                    border-0
                    cursor-pointer
                    btn-outline-primary
                  "
                  @click="useTemplate = true"
                >
                  <div class="card-body text-center">
                    <i class="far fa-magic fa-5x text-primary mb-4" />
                    <h5 class="fw-bold">Browse Templates</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketingEmailTemplateEditor from "./partials/MarketingEmailTemplateEditor";
import MarketingEmailTemplatesList from "./partials/MarketingEmailTemplatesList";
import Busy from "@/components/Busy";
export default {
  components: {
    Busy,
    MarketingEmailTemplatesList,
    MarketingEmailTemplateEditor,
  },
  data() {
    return {
      viewTemplate: null,
      addNew: false,
      useTemplate: null,
      defaultTemplates: [],
      loadingDefaults: true,
    };
  },
  methods: {
    fetchDefaultTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/default-marketing-email-templates")
        .then(({ data }) => {
          this.defaultTemplates = data.data;
          this.loadingDefaults = false;
        });
    },
    onSelectedTemplate(marketingEmailTemplate) {
      this.viewTemplate = marketingEmailTemplate;
    },
  },
  created() {
    this.fetchDefaultTemplates();
  },
};
</script>
